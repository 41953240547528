export default function ErrorContent() {
    return (
        <div className="container grid py-24 lg:grid-cols-12 lg:py-40">
            <div className="col-span-6 bg-lightGreen p-12 text-darkGreen-100">
                <h1 className="h2 mb-8 leading-none">Something&apos;s not working</h1>
                <p>We&apos;re experiencing some technical difficulty, please check back soon.<br />Apologies for the inconvenience.</p>
            </div>
        </div>
    )
}
