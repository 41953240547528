'use client'

import React, { useEffect } from 'react'
import ErrorContent from './ErrorContent'
import * as Sentry from '@sentry/nextjs'

interface ErrorPageType {
    error: Error & { digest?: string }
    reset: () => void
}

/**
 * https://nextjs.org/docs/app/api-reference/file-conventions/error
 */
export default function Error({
    error,
}: ErrorPageType) {
    useEffect(() => {
        Sentry.captureException(error)
    }, [error])

    return (
        <ErrorContent />
    )
}
